export const RESET_ERROR = 'RESET_ERROR';
export const LOADING = 'LOADING';
export const NEW_VIP_BOOKING_SUCCESS = 'NEW_VIP_BOOKING_SUCCESS';
export const NEW_VIP_BOOKING_FAIL = 'NEW_VIP_BOOKING_FAIL';
export const NEW_POINT_BOOKING_SUCCESS = 'NEW_POINT_BOOKING_SUCCESS';
export const NEW_POINT_BOOKING_FAIL = 'NEW_POINT_BOOKING_FAIL';

export const VEHICLE_AVAILABILITY_SUCCESS = 'VEHICLE_AVAILABILITY_SUCCESS'
export const VEHICLE_AVAILABILITY_FAIL = 'VEHICLE_AVAILABILITY_FAIL'

export const BOOKING_DATA = 'BOOKING_DATA';
export const SELECTED_VEHICLE = 'SELECTED_VEHICLE';
export const ORDER_DATA = 'ORDER_DATA';

export const NEW_BOOKING_SUCCESS = 'NEW_BOOKING_SUCCESS';
export const NEW_BOOKING_FAIL = 'NEW_BOOKING_FAIL';

export const RESET_BOOKING = 'RESET_BOOKING';

export const COUPON_VERIFY_SUCCESS = 'COUPON_VERIFY_SUCCESS';
export const COUPON_VERIFY_FAIL = 'COUPON_VERIFY_FAIL';

export const CREDIT_TERMS_SUCCESS = 'CREDIT_TERMS_SUCCESS';
export const CREDIT_TERMS_FAIL = 'CREDIT_TERMS_FAIL';

export const ADD_BOOKING = 'ADD_BOOKING'

export const REMOVE_ORDER_ITEM = 'REMOVE_ORDER_ITEM'

export const PASSENGERS_DETAILS = 'PASSENGERS_DETAILS'

export const RESET_ORDER_DATA = 'RESET_ORDER_DATA'

export const SET_SELECTED_BOOKING = 'SET_SELECTED_BOOKING'

export const BOOKING_TYPE = 'BOOKING_TYPE'

export const RESET_BOOKING_DATA = 'RESET_BOOKING_DATA'

export const BOOKING_INPUT = 'BOOKING_INPUT'

