import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SendIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { VIEW_URL } from '../config/Constants';

const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
    ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderBottomLeftRadius: 23,
      borderBottomRightRadius:23,
      borderTopLeftRadius:0,
      borderTopRightRadius:0,
      marginTop: theme.spacing(0),
      backgroundColor:'rgb(55, 65, 81)',
      minWidth: 180,
      color:
        theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '0px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        
      },
    },
  }));

const RegisterMenu = ({menuname}) => {

    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const handleNavMenu1 = () => {
      setAnchorEl(null);
      navigate('/customerregistration')
    };
    const handleNavMenu2 = () => {
      setAnchorEl(null);
      navigate('/b2bregistration')
    };
    const handleNavMenu3 = () => {
      setAnchorEl(null);
      navigate('/driverregistration')
    };
    const openInNewTab = (url) => {
      const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
      if (newWindow) newWindow.opener = null
    }

  return (
    <div>
        <Button 
          id="basic-button"
          className='regiclass'
          style={{ fontSize: '1rem', color:  '#333333' }}
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          endIcon={<SendIcon />}
        >
          {menuname}            
        </Button>
        <StyledMenu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
          'aria-labelledby': 'basic-button',
          }}    
        >
          <MenuItem className='MenuText' onClick={handleNavMenu1}>Customer Registration</MenuItem>
          <MenuItem className='MenuText' onClick={handleNavMenu2}>Company Registration</MenuItem>
          <MenuItem className='MenuText' onClick={()=>openInNewTab(`${VIEW_URL}driver-registration`)}>Driver Registration</MenuItem>
        </StyledMenu>
    </div>
  )
}



export default RegisterMenu
