import { 
    LOADING, 
    NEW_TOPUP_SUCCESS, 
    NEW_TOPUP_FAIL,
    TRANSACTION_LIST_FAIL,
    TRANSACTION_LIST_SUCCESS,
    BANK_LIST_SUCCESS,
    BANK_LIST_FAIL

} from "../constants/topupConstants";
import axios from '../../CustomAxios'
import { getProfile } from "./profileActions";

//Add Topup
export const addTopUp = (formData, userId) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    
    await axios.post(`addtopup`, formData)
    .then(async response => {
  
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch(getProfile(userId))
        dispatch({
            type: NEW_TOPUP_SUCCESS,
            payload: response.data
        })
       
    })
    .catch(async error => {
      

        dispatch({
            type: LOADING,
            payload: false
        })

        dispatch({
            type: NEW_TOPUP_FAIL,
            payload: error
        })

    });
}


//Get TRANSACTION
export const getTransactions = (data) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.get(`wallettransactions`,{
        params: data
    })
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })


        dispatch({
            type: TRANSACTION_LIST_SUCCESS,
            payload: response.data.data
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: TRANSACTION_LIST_FAIL,
            payload: error
        })
    });
}


//Get BANKLIST
export const getBanks = () => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.get(`banks`)
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
       

        dispatch({
            type: BANK_LIST_SUCCESS,
            payload: response.data.data
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: BANK_LIST_FAIL,
            payload: error
        })
    });
}



