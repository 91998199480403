
import { Button, ButtonGroup, Divider, Grid, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { BOOKING_TYPE, SELECTED_VEHICLE, SET_SELECTED_BOOKING } from '../../../Redux/constants/bookingConstants'
import reactotron from '../../../ReactronConfig'
import Vehicle from './Vehicle'

const MyTransfer = () => {

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const { vehicleAvailability } = useSelector(state => state.mytransfer)
    const { bookingData } = useSelector(state => state.booking)
    const { user, myTransfer } = useSelector(state => state.auth)
    const { markupLists } = useSelector(state => state.markup)



    useEffect(() => {
   
        //dispatch(getAvailabilities())
    }, [])

    const selectCurrentVehicle = (data) => {
        
        dispatch({
            type: BOOKING_TYPE,
            payload: 'MYTRANSFER'
        })

        let extraRate =  0;
        let rate = 0;
        let adminMarkup= 0;
        let agentmarkup = 0;
        let total = 0;
        let tax= 0;

        let extras = [];

        rate = data?.price;
        data?.extras.map(ext => {
            if(ext?.extraName === "Child seat" && bookingData?.childrens > 0){
                extraRate += ext?.price * bookingData?.childrens;
                extras.push({ extraId: ext?.extraId, amount: bookingData?.childrens })
            }
            else if(ext?.extraName === "Booster seat" && bookingData?.minors > 0){
                extraRate += ext?.price * bookingData?.minors;
                extras.push({ extraId: ext?.extraId, amount: bookingData?.minors })
            }
            
        }) 
        if(myTransfer?.value){
            if(myTransfer?.value_type === "Percentage" ){
                adminMarkup = (rate/100)*parseFloat(myTransfer?.value)
            }
            else{
                adminMarkup = myTransfer?.value
            }
        }
        

        if( user?.role === "agent" &&  markupLists?.type === "fixed"){
            
            if(markupLists?.status === 1){
                agentmarkup = parseFloat(markupLists?.value);
            }
            
            tax = ((rate + adminMarkup + extraRate + agentmarkup)/100)*parseFloat(myTransfer?.tax)
            
            total = rate + adminMarkup + extraRate + agentmarkup + tax;
        }
        else{
            if(user?.role === "agent" && markupLists?.status === 1){
                agentmarkup = (rate/100)*parseFloat(markupLists?.value)
            }
            
            tax = ((rate + adminMarkup + extraRate + agentmarkup)/100)*parseFloat(myTransfer?.tax)
            
            total = rate + adminMarkup + extraRate + agentmarkup + tax;
        }

        

        let datas = {
            ...data,
            price : rate,
            adminMarkup: adminMarkup,
            agentmarkup: agentmarkup,
            total: total,
            taxValue: tax,
            extraRate: extraRate,
            extras
        }
        dispatch({
            type: SELECTED_VEHICLE,
            payload: datas
        })

        dispatch({
            type: SET_SELECTED_BOOKING,
            payload: {
                booking: bookingData,
                selectedVehicle: datas,
                id: 'current'
            }
        })

        navigate("/passengerdetails")
        
    }


    return (
        

             <Grid container spacing={4}  mt={0}>
                {vehicleAvailability?.transferPriceList && vehicleAvailability?.transferPriceList.map((vehicle, index) => {
                    return(
                        <Grid  xs={12} md={4} id={index}  borderBottom="1px solid " borderColor={"rgba(112, 112, 112, 0.2)"}  paddingBottom={1} >
                            <Vehicle 
                                item={vehicle}
                            />
                        </Grid>
                        
                    )
                })}
            </Grid>
    )
}

export default MyTransfer